<template>
  <div :class="[baseClass + '-type-item']" @click="$router.push(`/type/${item}`)" v-if="item">
    <div :class="[baseClass + '-type-item-content']">
      <!-- {{ item }} -->
      <img class="img" :src="getImg(item)" />
    </div>
  </div>
</template>

<script>
const imgKeyHash = {
  ".IO": "IO",
  "3D": "3D ",
  Sports: "Sport",
  Boys: "Boy",
  Puzzles: "Puzzle",
  Girls: "Girl",
};
export default {
  props: ["item"],
  methods: {
    getImg (item) {
      let str = imgKeyHash[item] ? imgKeyHash[item] : item;
      let type = "";
      if (this.isPc) {
        if (this.isPid) {
          type = "pad"
        } else {
          type = "web"
        }
      } else {
        type = "phone"
      }
      return require("@/assets/types/sort／" + type + "／" + str + "@2x.png");
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-type-item {
  width: 20%;
  &-content {
    cursor: pointer;
    width: 260px;
    height: 100px;
    margin-bottom: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .#{$baseClass}-type-item {
    &-content {
      width: 146px;
      max-width: 95%;
      height: 56px;
      margin-bottom: 20px;
    }
  }
}
</style>
