<template>
  <div :class="baseClass + '-container-pagination'">
    <div
      :class="
        baseClass +
        '-container-pagination-left ' +
        (page == 0 ? 'disabled' : '')
      "
      @click="changePage('down')"
    >
      <img
        src="@/assets/images/games-new-page-icon.png"
        :class="baseClass + '-container-pagination-icon icon'"
      />
    </div>
    <div
      :class="
        baseClass +
        '-container-pagination-right ' +
        ((page + 1) * size >= length ? 'disabled' : '')
      "
      @click="changePage('up')"
    >
      <img
        src="@/assets/images/games-new-page-icon.png"
        :class="baseClass + '-container-pagination-icon'"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["page", "size", "length"],
  methods: {
    changePage(type) {
      this.$emit("change", type);
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-container-pagination {
  transform: rotateZ(0deg) skew(20deg, 0deg);
  width: 320px;
  height: 74px;
  background: #8cd54f;
  box-shadow: 0px 5px 10px 0px rgba(136, 208, 77, 0.5), 0px 2px 8px 0px #e1ffb9,
    0px -2px 8px 0px #5fa128 inset;
  border-radius: 8px;
  border: 4px solid #ffffff;
  overflow: hidden;
  position: absolute;
  bottom: -91px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: "";
    width: 2px;
    height: 44px;
    background: #7bbe42;
    box-shadow: 0px 10px 20px 0px rgba(136, 208, 77, 0.5);
    border-radius: 1px;
    position: absolute;
  }
  &-left {
    .icon {
      transform: rotateZ(180deg) skew(-20deg, 0deg);
    }
  }
  &-left,
  &-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: #7bbe42;
    }

    &.disabled {
      cursor: no-drop;
      &:hover {
        background-color: transparent;
      }
    }
  }
  &-icon {
    width: 34px;
    height: 34px;
    transform: skew(-20deg, 0deg);
  }
}
@media only screen and (max-width: 1300px) {
  .#{$baseClass}-container-pagination {
    width: 180px;
    height: 38px;
    bottom: -40px;
    &::after {
      width: 2px;
      height: 25px;
    }
    &-icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
