<template>
  <div :class="[baseClass + '-tips-container']">
    <div class="tips">
      <div class="logo-box">
        <img class="logo" @click="$router.push('/')" src="@/assets/images/logo.png" />
      </div>
      <div class="row">
        <div class="content">Kizgamer is an online gaming website, where you can find lots of games created by various
          developers on different languages and it's well-designed for all families. The platform interface is very simple
          and you can easily find your favorite game category.</div>
        <div class="content">In VoVoGamer.com you will certainly find the game suitable for your families with so many
          options including coloring games, jumping games, puzzle games, etc. Join VoVoGamer.com and spend hours of
          happy time with your families. </div>
        <div class="content">The algorithm to start playing games is very easy. First choose the Kizgamer, which you
          want to enjoy. Do not worry of your network speed is not as good as may be required. You are welcome to play
          the games not only at your home through the computer, but also using your smartphone or other devices.</div>
        <div class="content">One more good news is that you do not have to pass through millions of boring steps to
          start playing. The only things you need are the internet connection and a device to play. And they are all
          free!</div>
        <div class="actions">
              <router-link class="item" to="/about">About</router-link>
              <router-link class="item" to="/privacy">Privacy</router-link>
              <router-link class="item" to="/terms">Terms</router-link>
        </div>
        <div class="msg">
          © 2021 VoVoGamer.com- Kizgamer | The Best Online Games
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.#{$baseClass}-tips-container {
  color: #ffffff;
  .title {
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    margin-bottom: 16px;
  }
  .text {
    font-size: 14px;
    font-family: ArialMT;
  }
}
</style>
