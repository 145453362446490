var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.baseClass + '-index-page'},[_c('div',{class:_vm.baseClass + '-row'},[_c('dir',{class:_vm.baseClass + '-row-box'},[_c('div',{class:_vm.baseClass +
          '-top-container' +
          ' ' +
          _vm.baseClass +
          '-linear-border-container'},[_c('div',{class:_vm.baseClass +
            '-top-container-content' +
            ' ' +
            _vm.baseClass +
            '-linear-border-content'},[_c('div',{class:_vm.baseClass + '-top-container-title'},[_c('img',{class:_vm.baseClass + '-top-container-title-content',attrs:{"src":require("@/assets/images/top-title.png")}})]),_c('div',{class:_vm.baseClass + '-top-container-list'},_vm._l((_vm.$store.state.topGames),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"top"}})}),1)])]),_c('div',{class:_vm.baseClass + '-main'},[_c('Game',{attrs:{"item":_vm.$store.state.mainGame,"type":"main"}})],1)])],1),_c('div',{class:_vm.baseClass + '-ad-box'},[_c('div',{class:_vm.baseClass + '-ad-box-title'},[_vm._v("Advertisement")]),_c('div',{class:_vm.baseClass + '-ad-box-content'})]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass + '-type-container'},[_c('div',{class:_vm.baseClass + '-type-container-title'},[_vm._v("Game Series")]),_c('div',{class:_vm.baseClass + '-type-container-list'},_vm._l((_vm.$store.state.typeList),function(item,index){return _c('Type',{key:index,attrs:{"item":item}})}),1)])]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-new-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-new-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('img',{class:_vm.baseClass + '-new-container-title',attrs:{"src":require("@/assets/images/games-title-icon.png")}}),_c('div',{class:_vm.baseClass + '-new-container-list'},_vm._l((_vm.isPid ? _vm.newGames.slice(0, 10) : _vm.newGames),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"new"}})}),1),_c('div',{class:_vm.baseClass + '-new-container-pagination-container'},[_c('Pagination',{attrs:{"page":_vm.page,"size":_vm.pageSize,"length":_vm.$store.state.games.length},on:{"change":_vm.changePage}})],1)])])]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-all-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-all-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('img',{class:_vm.baseClass + '-all-container-title',attrs:{"src":require("@/assets/images/games-title-icon2.png")}}),_c('div',{class:_vm.baseClass + '-all-container-list'},_vm._l((_vm.$store.state.games.slice(0, 96)),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"all"}})}),1)])])]),_c('div',{class:_vm.baseClass + '-row'},[_c('Tips')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }