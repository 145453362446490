<template>
  <div :class="baseClass + '-index-page'">
    <div :class="baseClass + '-row'">
      <dir :class="baseClass + '-row-box'">
        <div
          :class="
            baseClass +
            '-top-container' +
            ' ' +
            baseClass +
            '-linear-border-container'
          "
        >
          <div
            :class="
              baseClass +
              '-top-container-content' +
              ' ' +
              baseClass +
              '-linear-border-content'
            "
          >
            <div :class="baseClass + '-top-container-title'">
              <img
                :class="baseClass + '-top-container-title-content'"
                src="~@/assets/images/top-title.png"
              />
            </div>
            <div :class="baseClass + '-top-container-list'">
              <Game
                v-for="item of $store.state.topGames"
                :item="item"
                :key="item.id"
                type="top"
              />
            </div>
          </div>
        </div>
        <div :class="baseClass + '-main'">
          <Game :item="$store.state.mainGame" type="main" />
        </div>
      </dir>
    </div>
    <div :class="baseClass + '-ad-box'">
      <div :class="baseClass + '-ad-box-title'">Advertisement</div>
      <div :class="baseClass + '-ad-box-content'"></div>
    </div>
    <div :class="baseClass + '-row'">
      <div :class="baseClass + '-type-container'">
        <div :class="baseClass + '-type-container-title'">Game Series</div>
        <div :class="baseClass + '-type-container-list'">
          <Type
            v-for="(item, index) of $store.state.typeList"
            :key="index"
            :item="item"
          />
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <div
        :class="
          baseClass +
          '-new-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        "
      >
        <div
          :class="
            baseClass +
            '-new-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          "
        >
          <img
            :class="baseClass + '-new-container-title'"
            src="@/assets/images/games-title-icon.png"
          />
          <div :class="baseClass + '-new-container-list'">
            <Game
              v-for="item of isPid ? newGames.slice(0, 10) : newGames"
              :item="item"
              :key="item.id"
              type="new"
            />
          </div>
          <div :class="baseClass + '-new-container-pagination-container'">
            <Pagination
              :page="page"
              :size="pageSize"
              @change="changePage"
              :length="$store.state.games.length"
            />
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <div
        :class="
          baseClass +
          '-all-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        "
      >
        <div
          :class="
            baseClass +
            '-all-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          "
        >
          <img
            :class="baseClass + '-all-container-title'"
            src="@/assets/images/games-title-icon2.png"
          />
          <div :class="baseClass + '-all-container-list'">
            <Game
              v-for="item of $store.state.games.slice(0, 96)"
              :item="item"
              :key="item.id"
              type="all"
            />
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <Tips />
    </div>
  </div>
</template>
<script>
import Game from "@/components/GameItem.vue";
import Type from "@/components/TypeItem.vue";
import Tips from "@/components/Tips.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  components: { Game, Type, Tips, Pagination },
  data() {
    return {
      newGames: [],
      page: 0,
      pageSize: 12,
    };
  },
  mounted() {
    this.getNewGames();
  },
  methods: {
    getNewGames() {
      this.newGames = []
        .concat(this.$store.state.games)
        .splice(this.page * this.pageSize, this.pageSize);
    },
    changePage(type) {
      if (type == "up") {
        if ((this.page + 1) * this.pageSize >= this.$store.state.games.length) {
          return false;
        } else {
          this.page++;
        }
      } else {
        if (this.page !== 0) {
          this.page--;
        } else {
          return false;
        }
      }
      this.getNewGames();
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/index.scss";
@import "~@/assets/scss/index_screen.scss";
</style>
